import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Layout from './components/Layout';
import Profile from "./components/Profile";
import ProductList from "./components/ProductList";

import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';

const App = () => {

    toast.configure({ 
        autoClose: 3000, 
        position: 'bottom-center', 
    });

    return (
        <>
            <Router>
                <Layout>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path='/' component={Profile} />
                        <Route path='/profile' component={Profile} />
                        <Route path='/products' component={ProductList} />
                    </Switch>
                    </Suspense>
                </Layout>                
            </Router>
        </>
    );
}

export default App;