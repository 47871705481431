import React from "react";
import { ReactBingmaps } from 'react-bingmaps';

import { placeOfKozani, placeOfPtolemaida } from '../../constants';

interface BingMapContainerState {
  zoom: number;
}

const openKozaniAddressOnMap = () => window.open(placeOfKozani, '_blank');
const openPtolemaidaAddressOnMap = () => window.open(placeOfPtolemaida, '_blank');

const pins = [
  {
    location: [40.3023571, 21.7981585],
    addHandler: "mouseover",
    infoboxOption: { title: 'Κατάστημα Κοζάνης', description: {} },
    infoboxAddHandler: { type: "click", callback: () => openKozaniAddressOnMap() },
    pushPinOption: { title: 'Κοζάνη', description: '' },
    pushPinAddHandler: { type: "click", callback: () => openKozaniAddressOnMap() },
  },
  {
    location: [40.509381, 21.674195],
    addHandler: "mouseover",
    infoboxOption: { title: 'Κατάστημα Πτολεμαϊδας', description: {} },
    infoboxAddHandler: { type: "click", callback: () => openPtolemaidaAddressOnMap() },
    pushPinOption: { title: 'Πτολεμαϊδα', description: '' },
    pushPinAddHandler: { type: "click", callback: () => openPtolemaidaAddressOnMap() },
  }
];

export class BingMapContainer extends React.PureComponent<{}, BingMapContainerState> {

  constructor(props: any) {
    super(props);
    this.state = {
      zoom: 8
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    setTimeout(() => {
      this.handleResize();
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  private handleResize = (e?: any) => {
    const mapHeight = document.getElementById("map")?.clientHeight ?? 0;
    this.setState(prevState => {
      return { zoom: (mapHeight < 200) ? 8 : 10 };
    });
  };

  render() {
    return (
      <ReactBingmaps 
        bingmapKey="AggSznTSZDRcxHsUqN_GPEpZwQ1Z0QRHWmw5ghcOZMrKmXFYvh5Fix4NIFjufwiQ"
        center={[40.41, 21.72]}
        zoom={this.state.zoom}
        infoboxesWithPushPins={pins}
      >
      </ReactBingmaps>
    );
  }
}

export default BingMapContainer;