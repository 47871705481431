import * as React from "react";

import Carousel, { CarouselProps } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const brands = [
    { key: '0', src: '/assets/img/brands/gesea.jpg', title: '', description: '' },
    { key: '1', src: '/assets/img/brands/baren.jpg', title: '', description: '' },
    { key: '2', src: '/assets/img/brands/epsolar.jpg', title: '', description: '' },
    { key: '3', src: '/assets/img/brands/exide.jpg', title: '', description: '' },
    { key: '4', src: '/assets/img/brands/royal.jpg', title: '', description: '' },
    { key: '5', src: '/assets/img/brands/incoe.jpg', title: '', description: '' },
    { key: '6', src: '/assets/img/brands/nova.jpg', title: '', description: '' },
    { key: '7', src: '/assets/img/brands/rottec.jpg', title: '', description: '' },
    { key: '8', src: '/assets/img/brands/gesea.jpg', title: '', description: '' },
    { key: '9', src: '/assets/img/brands/sharp.jpg', title: '', description: '' },
    { key: '10', src: '/assets/img/brands/royal.jpg', title: '', description: '' },
    { key: '11', src: '/assets/img/brands/sigma.jpg', title: '', description: '' },
    { key: '12', src: '/assets/img/brands/solite.jpg', title: '', description: '' },
    { key: '13', src: '/assets/img/brands/sunlight.jpg', title: '', description: '' },
    { key: '14', src: '/assets/img/brands/victron.jpg', title: '', description: '' },
    { key: '15', src: '/assets/img/brands/royal.jpg', title: '', description: '' },
    { key: '16', src: '/assets/img/brands/sharp.jpg', title: '', description: '' },
    { key: '17', src: '/assets/img/brands/sigma.jpg', title: '', description: '' },
    { key: '18', src: '/assets/img/brands/gesea.jpg', title: '', description: '' },
    { key: '19', src: '/assets/img/brands/nova.jpg', title: '', description: '' },
    { key: '20', src: '/assets/img/brands/incoe.jpg', title: '', description: '' },
];

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1,
    },
};

export class BrandsCarousel extends React.PureComponent<Partial<CarouselProps>> {

    render() {
        return (
            <Carousel
                responsive={this.props.responsive ?? responsive}
                arrows={this.props.arrows ?? false}
                keyBoardControl={this.props.keyBoardControl ?? false}
                swipeable={this.props.swipeable ?? true}
                infinite={this.props.infinite ?? true}
                autoPlay={this.props.autoPlay ?? true}
                autoPlaySpeed={this.props.autoPlaySpeed ?? 3000}
                customTransition={this.props.customTransition ?? "all 1s linear"}
                transitionDuration={this.props.transitionDuration ?? 500}
                additionalTransfrom={this.props.additionalTransfrom ?? (-28 * 5)}
            >
                {
                    brands.map(v => <img key={v.key} src={v.src} alt="" width="100px" height="90px" />)
                }
            </Carousel>
        );
    }
}