/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HashLink } from "react-router-hash-link";

import { facebookUrl, linkedInUrl } from '../constants';

const Footer = () =>
    (
        <>
            <footer className="relative bg-gray-300 pt-0 pb-6">

                {/*UI line*/}
                <div
                    className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                    style={{ height: "80px", transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-gray-300 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                {/*Content*/}
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap">

                        {/*Keep in touch*/}
                        <div className="w-full lg:w-6/12 px-4">
                            <h4 className="text-3xl font-semibold">
                                Let's keep in touch!
                            </h4>
                            <h5 className="text-lg mt-0 mb-2 text-gray-700">
                                Βρείτε μας στους παρακάτω συνδέσμους, θα ανταποκριθούμε άμεσα.
                            </h5>
                            {/*<div className="mt-6">
                                <button
                                    className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                    type="button"
                                >
                                    <i className="fab fa-twitter"></i>
                                </button>
                                <button
                                    className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                    type="button"
                                >
                                    <i className="fab fa-facebook-square"></i>
                                </button>
                                <button
                                    className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                    type="button"
                                >
                                    <i className="fab fa-dribbble"></i>
                                </button>
                                <button
                                    className="bg-white text-gray-900 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                    type="button"
                                >
                                    <i className="fab fa-github"></i>
                                </button>
                            </div>*/}
                        </div>

                        {/*Links and Resources*/}
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="flex flex-wrap items-top mb-6">

                                {/*Links*/}
                                <div className="mt-2 w-full lg:w-4/12 px-4 ml-auto">
                                    <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                                        Συνδεσμοι
                                    </span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <HashLink to="/#top" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <i className="fas fa-home mr-1"></i>
                                                <span>Αρχική σελίδα</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/#contact" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <i className="fas fa-comment-dots mr-1"></i>
                                                <span>Επικοινωνία</span>
                                            </HashLink>
                                        </li>                                        
                                        <li>
                                            <a target="_blank"
                                                rel="noopener noreferrer"
                                                href={facebookUrl}
                                                className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none"
                                            >
                                                <i className="fab fa-facebook mr-1"></i>
                                                <span>Facebook</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank"
                                                rel="noopener noreferrer"
                                                href={linkedInUrl}
                                                className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none"
                                            >
                                                <i className="fab fa-linkedin mr-1"></i>
                                                <span>LinkedIn</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                {/*Batteries*/}
                                <div className="mt-2 w-full lg:w-4/12 px-4">
                                    <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                                        Μπαταριες
                                    </span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <HashLink to="/products/#Μπαταρίες Αυτοκινήτων" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Αυτοκινήτων</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/products/#Μπαταρίες Φορτηγών" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Φορτηγών</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/products/#Μπαταρίες Μοτοσυκλετών" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Μοτοσυκλετών</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/products/#Ηλεκτρικών κινητήρων - Επαναφορτιζόμενες - Συναγερμών" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Συναγερμών</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/products/#Ηλεκτρικών κινητήρων και Φωτοβολταϊκών" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Ηλεκτρικών κινητήρων</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/products/#Ηλεκτρικών κινητήρων και Φωτοβολταϊκών" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Φωτοβολταϊκών συστημάτων</span>
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/products/#Ηλεκτρικών κινητήρων - Επαναφορτιζόμενες - Συναγερμών" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm outline-none">
                                                <span>Επαναφορτιζόμενες</span>
                                            </HashLink>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/*Copyright*/}
                    <hr className="my-6 border-gray-400" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-gray-600 font-semibold py-1">
                                Copyright © {new Date().getFullYear()}.{" "}
                                <a
                                    href="#"
                                    className="text-gray-600 hover:text-red-700 outline-none"
                                >
                                    The Battery Specialist.
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );

    export default Footer;