import axios, { AxiosRequestConfig, AxiosBasicCredentials } from 'axios';
import { mailJetUrl, mailJetUser, mailJetPwd, corsProxy } from './constants';

export const sendEmailAsync = async (subject: string, textPart: string, htmlPart: string) => {

    const credentials: AxiosBasicCredentials = {
        username: mailJetUser,
        password: mailJetPwd,
    };

    const config: AxiosRequestConfig = {
        auth: credentials,
    };

    const data = {
        Messages: [
            {
                From: {
                    Email: "bot@kentrompatarias.gr",
                    Name: "Battery-Bot"
                },
                To: [
                    {
                        Email: "kentrompatarias1@gmail.com",
                        Name: "ΚΕΝΤΡΟ ΜΠΑΤΑΡΙΑΣ ΠΤΟΛΕΜΑΪΔΑ-ΚΟΖΑΝΗ"
                    }
                ],
                Subject: subject,
                TextPart: textPart,
                HTMLPart: htmlPart,
            }
        ]
    }

    await axios.post(corsProxy+mailJetUrl, JSON.stringify(data), config)
    .then(v => {
        return v.data;
    })
    .catch(err => {
        throw err;
    });
};