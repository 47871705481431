export const addressKozani = 'Φιλίππου Β\' 27, Κοζάνη 50100';
export const addressPtolemaida = 'Μικράς Ασίας 30, Πτολεμαϊδα 50200';

const googlePlaceUrl = 'https://www.google.com/maps/place';
export const placeOfKozani = `${googlePlaceUrl}/${encodeURI(addressKozani)}`;
export const placeOfPtolemaida = `${googlePlaceUrl}/${encodeURI(addressPtolemaida)}`;

export const facebookUrl = `https://www.facebook.com/search/top/?q=${encodeURI('Κέντρο μπαταρίας Κοζάνη Πτολεμαϊδα')}`;
export const linkedInUrl = `https://www.linkedin.com/in/καταστηματα-μπαταριας-πτολεμαϊδα-κοζανη-a43081182`

export const mailJetUrl = 'https://api.mailjet.com/v3.1/send';
export const mailJetUser = 'b3edc34550d73a1b5f48bcd930b6acaa';
export const mailJetPwd = 'b3a8cbaeb0e4ad45abecd0c3a6872f47';

export const corsProxy = 'https://cors-anywhere.herokuapp.com/';