import React from "react";
import { Link } from "react-router-dom";

class Navbar extends React.PureComponent {

    state = { 
        isOpen: false, 
        isTransparent: true, 
        toggleMenuAction: () => this.setState({isOpen: !this.state.isOpen})
    };

    public render() {

        const facebookUrl = `https://www.facebook.com/search/top/?q=${encodeURI('Κέντρο μπαταρίας Κοζάνη Πτολεμαϊδα')}`;
        const [navbarTransparent, navbarOpen, setNavbarOpen] = [this.state.isTransparent, this.state.isOpen, this.state.toggleMenuAction];

        return (
            <>
                <nav id="top"
                    className={
                        (navbarTransparent
                            ? "top-0 absolute z-50 w-full"
                            : "relative shadow-lg bg-white shadow-lg") +
                        " flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
                    }
                >
                    <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                        {/*nav header and bars*/}
                        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                            <Link to="/"
                                className={
                                    (navbarTransparent ? "text-white" : "text-gray-800") +
                                    " text-2xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase outline-none"
                                }                            
                            >
                                <i className="fas fa-home mr-2"></i>
                                <span style={{ textShadow: "2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000" }}>
                                    Κεντρο Μπαταριας
                                </span>
                            </Link>
                            <span></span>

                            <button
                                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                                type="button"
                                onClick={() => setNavbarOpen()}
                            >
                                <i
                                    className={
                                        (navbarTransparent ? "text-white" : "text-gray-800") +
                                        " fas fa-bars"
                                    }
                                ></i>
                            </button>
                        </div>

                        {/*nav links*/}
                        <div
                            className={
                                "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
                                (navbarOpen ? " block rounded shadow-lg" : " hidden")
                            }
                            id="example-navbar-warning"
                        >
                            {/*
                            <ul className="flex flex-col lg:flex-row list-none mr-auto">
                                <li className="flex items-center">
                                    <a
                                        className={
                                            (navbarTransparent
                                                ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                                                : "text-gray-800 hover:text-gray-600") +
                                            " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                        }
                                        href="https://www.creative-tim.com/learning-lab/tailwindcss-starter-project#/profile"
                                    >
                                        <i
                                            className={
                                                (navbarTransparent
                                                    ? "lg:text-gray-300 text-gray-500"
                                                    : "text-gray-500") +
                                                " far fa-file-alt text-lg leading-lg mr-2"
                                            }
                                        />{" "}
                                        Docs
                                    </a>
                                </li>
                            </ul>
                            */}

                            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                                {/*Facebook*/}
                                <li className="flex items-center">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={ facebookUrl }
                                        onClick={() => setNavbarOpen()}
                                        className={
                                            (navbarTransparent
                                                ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                                                : "text-gray-800 hover:text-gray-600") +
                                            " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold outline-none"
                                        }
                                    >
                                        <i
                                            className={
                                                (navbarTransparent
                                                    ? "lg:text-gray-300 text-gray-500"
                                                    : "text-gray-500") +
                                                " fab fa-facebook text-lg leading-lg "
                                            }
                                        />
                                        <span className="/*lg:hidden*/ inline-block ml-2">Facebook</span>
                                    </a>
                                </li>

                                {/*
                                <li className="flex items-center">
                                    <a
                                        className={
                                            (navbarTransparent
                                                ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                                                : "text-gray-800 hover:text-gray-600") +
                                            " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                        }
                                        href="#pablo"
                                    >
                                        <i
                                            className={
                                                (navbarTransparent
                                                    ? "lg:text-gray-300 text-gray-500"
                                                    : "text-gray-500") +
                                                " fab fa-twitter text-lg leading-lg "
                                            }
                                        />
                                        <span className="lg:hidden inline-block ml-2">Tweet</span>
                                    </a>
                                </li>

                                <li className="flex items-center">
                                    <a
                                        className={
                                            (navbarTransparent
                                                ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                                                : "text-gray-800 hover:text-gray-600") +
                                            " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                        }
                                        href="#pablo"
                                    >
                                        <i
                                            className={
                                                (navbarTransparent
                                                    ? "lg:text-gray-300 text-gray-500"
                                                    : "text-gray-500") +
                                                " fab fa-github text-lg leading-lg "
                                            }
                                        />
                                        <span className="lg:hidden inline-block ml-2">Star</span>
                                    </a>
                                </li>
                                */}

                                {/*Products*/}
                                <li className="flex items-center">
                                    <Link to="/products"
                                        onClick={() => setNavbarOpen()}
                                        className={
                                            (navbarTransparent
                                                ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                                                : "text-gray-800 hover:text-gray-600") +
                                            " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold outline-none"
                                        }
                                    >
                                        <i
                                            className={
                                                (navbarTransparent
                                                    ? "lg:text-gray-300 text-gray-500"
                                                    : "text-gray-500") +
                                                " fas fa-plus-circle text-lg leading-lg "
                                            }
                                            style={{ transition: "all .15s ease" }}
                                        />
                                        <span className="/*lg:hidden*/ inline-block ml-2">Μπαταριες</span>                                    
                                    </Link>
                                    
                                    {/*
                                    <button
                                        className={
                                            (navbarTransparent
                                                ? "bg-white text-gray-800 active:bg-gray-100"
                                                : "bg-pink-500 text-white active:bg-pink-600") +
                                            " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                                        }
                                        type="button"
                                        style={{ transition: "all .15s ease" }}
                                    >
                                        <i className="fas fa-plus-circle fa-lg"></i>&nbsp;&nbsp;Μπαταριες
                                    </button>
                                    */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default Navbar;