import React from "react";

import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from "formik";

import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';

import { sendEmailAsync } from '../mailJet';

interface ContactState {
    submitting: boolean
}

export class Contact extends React.PureComponent<any, ContactState> {

    constructor(props: any) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    private ClipLoaderCSS = css({
        margin: '0 auto',
    });

    private ContactSchema = Yup.object().shape({
        message: Yup.string()
            .min(5, 'Το μήνυμα πρέπει να είναι τουλάχιστον 5 χαρακτήρες.')
            .max(2000, 'Το μήνυμα δεν μπορεί να είναι μεγαλύτερο απο 2000 χαρακτήρες.')
    });

    render() {
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        return (
            <>
                <Formik
                    initialValues={{
                        message: '',
                    }}
                    validationSchema={this.ContactSchema}
                    onSubmit={(fields, actions) => {
                        if (fields.message.length < 1)
                            return;
                        this.setState({ submitting: true })
                        sleep(600).then(() => {
                            sendEmailAsync('Μήνυμα απο kentrompatarias.gr', fields.message, fields.message)
                                .then(v => {
                                    fields.message = '';
                                    toast.success(
                                        <>
                                            <i className="fas fa-envelope-open" style={{ marginRight: '.6rem' }} />
                                            <span>Ευχαριστούμε! Το μήνυμα στάλθηκε επιτυχώς.</span>
                                        </>);
                                })
                                .catch(err => {
                                    toast.error(
                                        <>
                                            <i className="fas fa-envelope-open" style={{ marginRight: '.6rem' }} />
                                            <span>Η αποστολή του μηνύματος απέτυχε.</span>
                                        </>);
                                })
                                .finally(() => {
                                    this.setState({ submitting: false });
                                });
                        });
                    }}
                >
                    {({ errors, touched, isSubmitting, submitCount, isValidating }) => (
                        <Form>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-800 text-xs font-bold mb-1">
                                    Στειλτε μας μηνυμα
                                </label>
                                <label className="block text-gray-600 text-xs font-base mb-2">
                                    Αν θέλετε να επικοινωνήσουμε μαζί σας, παραθέστε στο μήνυμα το email ή το τηλέφωνό σας.
                                </label>                                
                                <Field
                                    as="textarea"
                                    name="message"
                                    placeholder="Ρωτήστε μας οτιδήποτε, κάντε ενα σχόλιο ή εκφράστε ενα παράπονο..."
                                    className="px-3 py-3 w-full placeholder-gray-500 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline"
                                    style={{ transition: "all .15s ease" }}
                                />
                                <div className="text-xs text-red-800">
                                    <ErrorMessage name="message" />
                                </div>
                            </div>
                            <div className="relative w-full text-right">
                                <button type="submit"
                                    hidden={this.state.submitting}
                                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md font-bold text-xs"
                                    style={{ transition: "all .15s ease" }}
                                >
                                    Αποστολη
                                </button>
                                <ClipLoader
                                    css={this.ClipLoaderCSS}
                                    size={25}
                                    color={"#718096"}
                                    loading={this.state.submitting}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}