import React from "react";

interface ProductListItemProps {
    imageUrl: string,
    title: string,
    description: string | JSX.Element,
    className?: string,
}

class ProductListItem extends React.PureComponent<ProductListItemProps> {

    render() {
        return (
            <>
                <div className={this.props.className}>
                    <div className="flex flex-wrap justify-left text-justify">
                        <div className="" style={{ minWidth: "150px" }}>
                            <img className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                                style={{ width: "120px" }}
                                src={this.props.imageUrl}
                                alt=""
                            />
                        </div>
                        <div className="" style={{ maxWidth: '80%' }}>
                            <p className="mb-4 text-base font-normal leading-relaxed text-gray-800 text-bottom">
                                <span className="font-semibold">{this.props.title}</span>
                                <br />
                                <span>{this.props.description}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductListItem;