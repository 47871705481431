import React from 'react';

import Navbar from "./Navbar";
import Footer from "./Footer";

import "@fortawesome/fontawesome-free/css/all.min.css";

const Layout = (props: { children?: React.ReactNode }) => {
    return (
        <>
            <Navbar />
            {props.children}
            <Footer />
        </>
    )};

export default Layout;