import React from "react";

import ProductListItem from '../components/ProductListItem';

const CarBatteriesSection = [
    { key:'cb1', src: '/assets/img/products/gesea.jpg', title: 'Gesea', 
        description: 
        <>
            Μπαταρίες εισαγωγής μας με όλα τα ποιοτικά χαρακτηριστικά μιας κορυφαίας μπαταρίας.
        </>
    },
    { key:'cb2', src: '/assets/img/products/baren auto.jpg', title: 'Baren', 
        description: 
        <>
            Οι θρυλικές μπαταρίες BAREN ασβεστίου λιθίου από το Ιταλικό εργοστάσιο της FIAMM, 
            ένα από τα καλύτερα εργοστάσια μπαταριών στον κόσμο.
        </>
    },
    { key:'cb3', src: '/assets/img/products/exide auto.jpg', title: 'Exide', 
        description: 
        <>
            Υψηλής ποιότητας μπαταρίες με υψηλής ενέργειας εκκίνησης κάτω από όλες τις καιρικές συνθήκες.<br/> 
            Χρησιμοποιούνται για δυνατούς βενζινοκινητήρες, πετρελαιοκινητήρες με standard εξοπλισμούς ή και ανώτερους 
            για καθημερινή χρήση.<br/>
            Με πατενταρισμένο σύστημα λαβυρίνθου.
        </>
    },
    { key:'cb4', src: '/assets/img/products/nova auto.jpg', title: 'Nova', 
        description: 
        <>
            Μπαταρίες ενός από των μεγαλύτερων εργοστασίων της Κορεάτικης αγοράς GLOBAL SEBANG.
        </> 
    },
    { key:'cb5', src: '/assets/img/products/royal auto.jpg', title: 'Royal', 
        description: 
        <>
            Οι καλύτεροι συσσωρευτές παγκοσμίως, απο το κορυφαίο εργοστάσιο της Delkor, που βραβεύονται τα τελευταία 
            12 συνεχή χρόνια με το Korean Standard Quality Index KS-QEI Award ως προιόντα αναφοράς και ύψιστης ποιότητας.<br/>
            Προδιαγραφών ΟΕ.
        </>
    },
    { key:'cb6', src: '/assets/img/products/sigma auto.jpg', title: 'Sigma', 
        description: 
        <>
            Μπαταρίες από εργοστάσιο Κορέας SOLITE πρώτη εφαρμογή πολλών αυτοκινήτων.
        </>
    },
    { key:'cb7', src: '/assets/img/products/varta auto.jpg', title: 'Varta', 
        description: 
        <>
            Μπαταρίες με κορυφαία ποιότητα.
        </>
    },
    { key:'cb8', src: '/assets/img/products/incoe battery.jpg', title: 'Incoe', 
        description:
        <>
            Μπαταρία OEM (παραγωγός πρωτότυπου εξοπλισμού).
        </>
    },
];

const TruckBatteriesSection = [
    { key:'tb1', src: '/assets/img/products/exide truck bus.jpg', title: 'Exide', 
        description: 
        <>
            Υψηλής ποιότητας μπαταρίες με υψηλής ενέργειας εκκίνησης κάτω από όλες τις καιρικές συνθήκες.<br/> 
            Χρησιμοποιούνται για δυνατούς βενζινοκινητήρες, πετρελαιοκινητήρες με standard εξοπλισμούς ή και ανώτερους 
            για καθημερινή χρήση.<br/>
            Με πατενταρισμένο σύστημα λαβυρίνθου.
        </>
    },
    { key:'tb2', src: '/assets/img/products/varta truck bus.jpg', title: 'Varta', 
        description: 
        <>
            Μπαταρίες με κορυφαία ποιότητα.
        </>
    },
];

const MotoBatteriesSection = [
    { key:'mb1', src: '/assets/img/products/sigma moto.jpg', title: 'Sigma', 
        description: 
        <>
            Μπαταρίες από εργοστάσιο Κορέας SOLITE πρώτη εφαρμογή πολλών αυτοκινήτων.
        </>
    },
    { key:'mb2', src: '/assets/img/products/sigma moto 1.jpg', title: 'Sigma', 
        description: 
        <>
            Μπαταρίες από εργοστάσιο Κορέας SOLITE πρώτη εφαρμογή πολλών αυτοκινήτων.
        </>
    },
];

const ElectricAndSunSection = [
    { key:'es1', src: '/assets/img/products/sunlight battery.jpg', title: 'Sunlight', 
        description:
        <>
            Η εταιρεία έχει παγκόσμια παρουσία στον τομέα των ολοκληρωμένων λύσεων ενέργειας και ειδικεύεται στην ανάπτυξη, 
            την παραγωγή και τη διάθεση μπαταριών και συστημάτων αποθήκευσης ενέργειας για βιομηχανικές και καταναλωτικές εφαρμογές 
            και εφαρμογές προηγμένης τεχνολογίας.
        </>
    },
    { key:'es2', src: '/assets/img/products/sunlight electric.jpg', title: 'Sunlight', 
        description:
        <>
            Η εταιρεία έχει παγκόσμια παρουσία στον τομέα των ολοκληρωμένων λύσεων ενέργειας και ειδικεύεται στην ανάπτυξη, 
            την παραγωγή και τη διάθεση μπαταριών και συστημάτων αποθήκευσης ενέργειας για βιομηχανικές και καταναλωτικές εφαρμογές 
            και εφαρμογές προηγμένης τεχνολογίας.
        </>
    },
    { key:'es3', src: '/assets/img/products/24v-rottec.png', title: 'Rottec', 
        description:
        <>
            Ολοκληρωμένα συστήματα σε συσσωρευτές solar ups standby.
        </>
    },
    { key:'es4', src: '/assets/img/products/yuasa.jpg', title: 'Yuasa', 
        description:
        <>
            Οι συσσωρευτές YUASA NP είναι σχεδιασμένοι με την τεχνολογία OXYGEN recombination.<br/>
            H σειρά YUASA VRLA μπαταρίες λόγω εργοστασιακής κατασκευής παρέχουν τη μέγιστη ασφάλεια διαρροής ηλεκτρολύτη 
            από το περίβλημα ή από τους τερματικούς σταθμούς της μπαταρίας και συνεπώς δεν υπάρχει ανάγκη συντήρησης.
        </>
    },
];

const ElectricRechargableAndAlarmsSection = [
    { key:'era1', src: '/assets/img/products/sigma vrla.jpg', title: 'Sigma', 
        description: 
        <>
            Μπαταρίες από εργοστάσιο Κορέας SOLITE πρώτη εφαρμογή πολλών αυτοκινήτων.
        </>
    },
    { key:'era2', src: '/assets/img/products/sigma vrla 1.jpg', title: 'Sigma', 
        description: 
        <>
            Μπαταρίες από εργοστάσιο Κορέας SOLITE πρώτη εφαρμογή πολλών αυτοκινήτων.
        </>
    },
];

class ProductList extends React.PureComponent {

    render() {
        return (
            <>
                <main className="productList-page">

                    {/*Header*/}
                    <section className="relative block" style={{ height: "380px" }}>

                        {/*Logo*/}
                        <div
                            className="absolute top-0 w-full h-full bg-cover"
                            style={{
                                backgroundPosition: "top center",
                                backgroundImage: "url('/assets/img/batteries-in-row.jpg')",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <span
                                id="blackOverlay"
                                className="w-full h-full absolute bg-black" style={{ opacity: .6 }}
                            ></span>
                        </div>

                        {/*Curved UI line*/}
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
                            style={{ height: "70px", transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 98"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-gray-300 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>

                    {/*Body*/}
                    <section className="relative pt-1 pb-16 bg-gray-300">

                        {/*Logo and Content*/}
                        <div className="container mx-auto px-4">

                            {/*Logo*/}
                            <div className="relative flex flex-row justify-center lg:justify-start -mt-64" >
                                <img
                                    src="/assets/img/logo.png"
                                    className="h-auto w-auto border-none"
                                    style={{ maxHeight: "16rem", zIndex: 1 }}
                                    alt=""
                                />
                            </div>

                            {/*CarBatteries*/}
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-32" style={{ opacity: .9 }}>                                
                                <div className="mt-32 px-6 py-10 /*border-t border-gray-300*/ text-justify">
                                    <p id="Μπαταρίες Αυτοκινήτων" className="mb-10 text-gray-700 font-semibold block text-2xl">
                                        <span>Μπαταρίες Αυτοκινήτων</span>
                                        <span className="block px-6 border-t border-gray-300"></span>
                                    </p>
                                    {CarBatteriesSection.map(v => <ProductListItem className="mb-8" key={v.key} description={v.description} title={v.title} imageUrl={v.src} />)}
                                </div>
                            </div>

                            {/*TruckBatteries*/}
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg" style={{ opacity: .9 }}>
                                <div className="mt-2 px-6 py-10 text-justify">
                                    <p id="Μπαταρίες Φορτηγών" className="mb-10 text-gray-700 font-semibold block text-2xl">
                                        <span>Μπαταρίες Φορτηγών</span>
                                        <span className="block px-6 border-t border-gray-300"></span>
                                    </p>
                                    {TruckBatteriesSection.map(v => <ProductListItem className="mb-8" 
                                        key={v.key} description={v.description} title={v.title} imageUrl={v.src} />)}
                                </div>
                            </div>

                            {/*MotoBatteries*/}
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg" style={{ opacity: .9 }}>
                                <div className="mt-2 px-6 py-10 text-justify">
                                    <p id="Μπαταρίες Μοτοσυκλετών" className="mb-10 text-gray-700 font-semibold block text-2xl">
                                        <span>Μπαταρίες Μοτοσυκλετών</span>
                                        <span className="block px-6 border-t border-gray-300"></span>
                                    </p>
                                    {MotoBatteriesSection.map(v => <ProductListItem className="mb-8" key={v.key} description={v.description} title={v.title} imageUrl={v.src} />)}
                                </div>
                            </div>

                            {/*ElectricAndSun*/}
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg" style={{ opacity: .9 }}>
                                <div className="mt-2 px-6 py-10 text-justify">
                                    <p id="Ηλεκτρικών κινητήρων και Φωτοβολταϊκών" className="mb-10 text-gray-700 font-semibold block text-2xl">
                                        <span>Ηλεκτρικών κινητήρων και Φωτοβολταϊκών</span>
                                        <span className="block px-6 border-t border-gray-300"></span>
                                    </p>
                                    {ElectricAndSunSection.map(v => <ProductListItem className="mb-8" key={v.key} description={v.description} title={v.title} imageUrl={v.src} />)}
                                </div>
                            </div>

                            {/*ElectricRechargableAndAlarms*/}
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg" style={{ opacity: .9 }}>
                                <div className="mt-2 px-6 py-10 text-justify">
                                    <p id="Ηλεκτρικών κινητήρων - Επαναφορτιζόμενες - Συναγερμών" className="mb-10 text-gray-700 font-semibold block text-2xl">
                                        <span>Ηλεκτρικών κινητήρων - Επαναφορτιζόμενες - Συναγερμών</span>
                                        <span className="block px-6 border-t border-gray-300"></span>
                                    </p>
                                    {ElectricRechargableAndAlarmsSection.map(v => <ProductListItem className="mb-8" key={v.key} description={v.description} title={v.title} imageUrl={v.src} />)}
                                </div>
                            </div>

                        </div>
                    </section>

                </main>
            </>
        );
    }
}

export default ProductList;