import React from 'react';

import MapContainer from '../components/maps/BingMap';
import { addressKozani, addressPtolemaida } from '../constants';

import { Contact } from '../components/Contact';
import { BrandsCarousel } from '../components/BrandsCarousel';

class Profile extends React.PureComponent {

    public render() {

        const googleDirectionsUrl = 'https://www.google.com/maps/dir';
        const directionsToKozani = `${googleDirectionsUrl}/?api=1&origin=&destination=${encodeURI(addressKozani)}&travelmode=driving`;
        const directionsToPtolemaida = `${googleDirectionsUrl}/?api=1&origin=&destination=${encodeURI(addressPtolemaida)}&travelmode=driving`;

        return (
            <>
                <main className="profile-page">

                    {/*Header*/}
                    <section className="relative block" style={{ height: "380px" }}>

                        {/*Logo*/}
                        <div
                            className="absolute top-0 w-full h-full bg-cover"
                            style={{
                                backgroundPosition: "top center",
                                backgroundImage: "url('/assets/img/batteries-in-row.jpg')",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <span
                                id="blackOverlay"
                                className="w-full h-full absolute bg-black" style={{ opacity: .6 }}
                            ></span>
                        </div>

                        {/*Curved UI line*/}
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
                            style={{ height: "70px", transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 98"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-gray-300 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>

                    {/*Body*/}
                    <section className="relative pt-1 pb-16 bg-gray-300">

                        {/*Logo and Content*/}
                        <div className="container mx-auto px-4">

                            {/*Logo*/}
                            <div className="relative flex flex-row justify-center lg:justify-start -mt-64" >
                                <img
                                    alt=""
                                    src="/assets/img/logo.png"
                                    className="h-auto w-auto border-none"
                                    style={{ maxHeight: "16rem", zIndex: 1 }}
                                />
                            </div>

                            {/*Content*/}
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-32" style={{ opacity: .9 }}>

                                {/*<div className="text-center mt-32">
                                    <h3 className="text-5xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                                        Κέντρο Μπαταρίας
                                    </h3>
                                </div>*/}

                                <div className="mt-32 px-6 py-10 /*border-t border-gray-300*/ text-justify">
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-full lg:w-9/12">
                                            <p className="mb-4 text-lg leading-relaxed text-gray-800">
                                                Στα καταστήματα <strong>Κέντρο Μπαταρίας</strong> ασχολούμαστε με την μπαταρία από το 1999 
                                                και προσπαθούμε να είμαστε επίκαιροι στις νέες τεχνολογίες και εφαρμογές των μπαταριών.
                                            </p>
                                            <p className="mb-4 text-lg leading-relaxed text-gray-800">
                                                Εισάγουμε και εμπορευόμαστε μπαταρίες από τα μεγαλύτερα και πιο αξιόπιστα εργοστάσια του κόσμου. 
                                                Διαθέτουμε μπαταρίες αυτοκινήτων, μπαταρίες μοτοσυκλετών, φορτηγών, φωτοβολταϊκων και πολλών άλλων ειδών.
                                            </p>

                                            {/*
                                            <p className="mb-4 p-image-left">
                                                <p className="mb-4 pl-4 text-lg leading-relaxed text-gray-800">
                                                    Duis ullamcorper tortor id pharetra mollis. Ut dignissim sagittis orci id blandit.<br />
                                                    Vivamus molestie venenatis eros non ullamcorper.
                                                    Aliquam risus arcu, molestie id congue eu, feugiat at metus.
                                                </p>
                                            </p>
                                            */}

                                            <p className="mb-4 text-lg leading-relaxed text-gray-800">
                                                Η μεγάλη γκάμα μπαταριών <strong>άριστης ποιότητας</strong> και <strong>προσιτής τιμής</strong> που σας παρέχουμε, 
                                                καθώς και ο διαγνωστικός έλεγχος που πραγματοποιούμε δωρεάν για εσάς, 
                                                μας καθιστούν από τις πρώτες εταιρείες προτίμησης των πελατών.
                                            </p>

                                            <div className="mb-4 mt-8 text-lg leading-relaxed text-gray-800 border-t border-gray-300">
                                                <br />
                                                <i className="fas fa-dolly-flatbed fa-2x mr-2 text-sm text-red-600"></i>
                                                <span>Είστε επαγγελματίας...; εάν ναι, επικοινωνήστε μαζί μας για να σας αποστείλουμε την προσφορά μας.</span>
                                                <p className="text-sm">
                                                    Για την καλύτερη ενημέρωση των προϊόντων μας έχετε τη δυνατότητα, 
                                                    εφόσον είστε έμπορος και διατηρείται κατάστημα, 
                                                    να ενημερωθείτε και να αποκτήσετε τα προϊόντα μας.
                                                </p>
                                            </div>

                                            <div className="mb-4 mt-16 text-lg leading-relaxed text-gray-800">
                                                <BrandsCarousel />
                                            </div>

                                            {/*
                                            <p className="text-center">
                                                <a
                                                    href="#"
                                                    className="font-normal text-blue-600"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    Show more »
                                                </a>
                                            </p>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Address and Map*/}
                        <div className="container mx-auto px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 py-10 shadow-xl rounded-lg" style={{ opacity: .9 }}>
                                <div className="px-12">
                                    <div className="flex flex-wrap">

                                        {/*Address*/}
                                        <div className="" style={{ flexGrow: .1 }}>
                                            {/*Kozani*/}
                                            <div className="mb-2 text-gray-700">
                                                <div className="">
                                                    <p>
                                                        <span style={{ fontWeight: "bold" }}>Κατάστημα Κοζάνης</span>
                                                    </p>
                                                    <p className="mt-1">
                                                        <i className="fas fa-map-marker-alt ml-1 mr-2 text-sm text-gray-500"></i>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={directionsToKozani}
                                                            className="font-normal text-blue-600 outline-none"
                                                        >
                                                            <span>{addressKozani.split(',')[0]}</span>
                                                        </a>
                                                    </p>

                                                    <p className="mt-1">
                                                        <i className="fas fa-phone mr-2 text-sm text-gray-500"></i>
                                                        <a
                                                            href="tel:+302461102798"
                                                            className="font-normal text-blue-600 outline-none"
                                                        >
                                                            <span>2461 102798</span>
                                                        </a>
                                                    </p>

                                                    <p className="mt-1">
                                                        <i className="fab fa-facebook-messenger mr-2 text-sm text-gray-500"></i>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://m.me/batterycenterkozani"
                                                            className="font-normal text-blue-600 outline-none"
                                                        >
                                                            <span>Messenger</span>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                            {/*Ptolemaida*/}
                                            <div className="mb-2 mt-10 text-gray-700">
                                                <div className="">
                                                    <p>
                                                        <span style={{ fontWeight: "bold" }}>Κατάστημα Πτολεμαϊδας</span>
                                                    </p>
                                                    <p className="mt-1">
                                                        <i className="fas fa-map-marker-alt ml-1 mr-2 text-sm text-gray-500"></i>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={directionsToPtolemaida}
                                                            className="font-normal text-blue-600 outline-none"
                                                        >
                                                            <span>{addressPtolemaida.split(',')[0]}</span>
                                                        </a>
                                                    </p>

                                                    <p className="mt-1">
                                                        <i className="fas fa-phone mr-2 text-sm text-gray-500"></i>
                                                        <a
                                                            href="tel:+302463022666"
                                                            className="font-normal text-blue-600 outline-none"
                                                        >
                                                            <span>24630 22666</span>
                                                        </a>
                                                    </p>

                                                    <p className="mt-1">
                                                        <i className="fab fa-facebook-messenger mr-2 text-sm text-gray-500"></i>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://m.me/batterycenterptolemaida"
                                                            className="font-normal text-blue-600 outline-none"
                                                        >
                                                            <span>Messenger</span>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                            {/*Manager*/}
                                            <div className="mb-2 mt-10 text-gray-700">
                                                <div className="">
                                                    <p className="mt-1">
                                                        <i className="fas fa-briefcase mr-2 text-sm text-gray-500"></i>
                                                        <span>Υπεύθυνος: Γιώργος Iωαννίδης</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Map*/}
                                        <div id="map" className="flex-grow" style={{ minWidth: "150px" }}>
                                            <div className="relative h-full w-full text-center" style={{ minHeight: "20vh" }}>
                                                <MapContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Contact*/}
                        <div id="contact" className="container mx-auto px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 py-10 shadow-xl rounded-lg" style={{ opacity: .9 }}>
                                <div className="px-12">
                                    <Contact />
                                </div>
                            </div>
                        </div>

                    </section>

                </main>
            </>
        );
    }
};

export default Profile;